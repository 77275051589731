<template>
    <section class="container" >
        <div class="sabc_livecenter_section">
            <div class="sabc-sports-list">
                <label @click="activeSport = 'all'">Matches:</label>
                <span class="sport sport-soccer" :class="activeSport == 'soccer'?'active':'' " @click="activeSport = 'soccer'" >Soccer</span>
                <span class="sport sport-rugby" :class="activeSport == 'rugby'?'active':'' " @click="activeSport = 'rugby'">Rugby</span>
                <span class="sport sport-cricket" :class="activeSport == 'cricket'?'active':'' " @click="activeSport = 'cricket'">Cricket</span>
            </div>
            <div class="swiper mySwiper2">
                <div class="swiper-wrapper" >

                    <section class="swiper-slide" :class="(activeSport == 'all')?'':'d-none' " v-for="match in soccer" :key="match.id">
                        <div class="sabc_livecenter_box">
                            <router-link :to="{ name: 'match_detail.overview', params: { slug: $slugify(match.tournament.name + ' ' + $getSafe(()=>match.competitors[0].team.name) + ' vs ' + $getSafe(()=>match.competitors[1].team.name)), date: getMatchDate(match.datetime), matchId: match.id } }">
                                <ul class="match-head-ul">
                                    <li>
                                        <!-- <strong class=" text-uppercase"> {{ (match.status=='NSY')?' Not Started ': (match.status == 'inprogress')?match.current_period:match.status}} </strong> -->
                                        <span v-if="match.status == 'inprogress'" class="live">LIVE</span>
                                        <strong class="sport-name"> Soccer </strong>
                                    </li>
                                    <!-- <li>{{ match.tournament.footballCategory.name }} - {{ match.tournament.name }} </li> -->
                                    <li>{{ match.tournament.name }} </li>
                                </ul>
                                <div class="matchcard-body">
                                    <div class="playcard-col">
                                        <div class="team-wrap" v-if="match.competitors[0].is_home">
                                            <div class="team-logo">
                                                <img loading="lazy" :onerror="$teamDefaultLogo()" :src="(sportLogoPath['soccer'] + match.competitors[0].team.id + '_40x40.webp')" :alt="match.competitors[0].team.name" width="40" height="40">
                                            </div>
                                            <span class="team-title">
                                                <span class="team-title-in">
                                                    <span class="in-name ">{{ match.competitors[0].team.name }}</span>
                                                </span>
                                            </span>
                                        </div>
                                        <div class="team-wrap" v-else>
                                            <div class="team-logo">
                                                <img loading="lazy" :onerror="$teamDefaultLogo()" :src="(sportLogoPath['soccer'] + match.competitors[1].team.id + '_40x40.webp')" :alt="match.competitors[1].team.name" width="40" height="10">
                                            </div>
                                            <span class="team-title">
                                                <span class="team-title-in">
                                                    <span class="in-name ">{{ match.competitors[1].team.name }}</span>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="playcard-col" v-if="match.status == 'inprogress'">
                                        <div class="active team-wrap"><span class="competition-text">{{match.cfs}}</span></div>
                                    </div>
                                    <div class="playcard-col" v-else>
                                        <div class="active team-wrap"><span class="competition-text">vs</span></div>
                                    </div>
                                    <div class="playcard-col">
                                        <div class="team-wrap" v-if="!match.competitors[1].is_home">
                                            <div class="team-logo">
                                                <img loading="lazy" :onerror="$teamDefaultLogo()" :src="(sportLogoPath['soccer'] + match.competitors[1].team.id + '_40x40.webp')" :alt="match.competitors[1].team.name" width="53" height="36">
                                            </div>
                                            <span class="team-title">
                                                <span class="team-title-in">
                                                    <span class="in-name ">{{ match.competitors[1].team.name }}</span>
                                                </span>
                                            </span>
                                        </div>
                                        <div class="team-wrap" v-else>
                                            <div class="team-logo">
                                                <img loading="lazy" :onerror="$teamDefaultLogo()" :src="(sportLogoPath['soccer'] + match.competitors[0].team.id + '_40x40.webp')" :alt="match.competitors[0].team.name" width="53" height="36">
                                            </div>
                                            <span class="team-title">
                                                <span class="team-title-in">
                                                    <span class="in-name ">{{ match.competitors[0].team.name }}</span>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <!-- <p>{{ (match.status=='inprogress') ? match.current_period : getCurrentTimeZone(match.datetime) }}</p> -->
                                <p v-if="match.status=='inprogress'">{{ match.current_period }}</p>
                                <p v-else>{{ match.datetime | matchSliderDateTime }}</p>
                            </router-link>
                        </div>
                    </section>

                    <section class="swiper-slide" :class="(activeSport == 'all')?'':'d-none' " v-for="match in rugby" :key="match.id">
                        <div class="sabc_livecenter_box">
                            <router-link :to="{ name: 'rugby_match_detail.overview', params: { slug: $slugify(match.tournament.name + ' ' + $getSafe(()=>match.competitors[0].team.name) + ' vs ' + $getSafe(()=>match.competitors[1].team.name)), date: getMatchDate(match.datetime), matchId: match.id } }">
                                <ul class="match-head-ul">
                                    <li>
                                        <!-- <strong class=" text-uppercase"> {{ (match.status=='NSY')?' Not Started ': (match.status == 'inprogress')?match.current_period:match.status}} </strong> -->
                                        <span v-if="match.status == 'inprogress'" class="live">LIVE</span>
                                        <strong class="sport-name"> Rugby </strong>
                                    </li>
                                    <!-- <li>{{ match.tournament.footballCategory.name }} - {{ match.tournament.name }} </li> -->
                                    <li> {{ match.tournament.name }} </li>
                                </ul>
                                <div class="matchcard-body">
                                    <div class="playcard-col">
                                        <div class="team-wrap" v-if="match.competitors[0].is_home">
                                            <div class="team-logo">
                                                <img loading="lazy" :onerror="$teamDefaultLogo()" :src="(sportLogoPath['rugby'] + match.competitors[0].team.id + '_90x90.webp')" :alt="match.competitors[0].team.name" width="53" height="36">
                                            </div>
                                            <span class="team-title">
                                                <span class="team-title-in">
                                                    <span class="in-name ">{{ match.competitors[0].team.name }}</span>
                                                </span>
                                            </span>
                                        </div>
                                        <div class="team-wrap" v-else>
                                            <div class="team-logo">
                                                <img loading="lazy" :onerror="$teamDefaultLogo()" :src="(sportLogoPath['rugby'] + match.competitors[1].team.id + '_90x90.webp')" :alt="match.competitors[1].team.name" width="53" height="36">
                                            </div>
                                            <span class="team-title">
                                                <span class="team-title-in">
                                                    <span class="in-name ">{{ match.competitors[1].team.name }}</span>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="playcard-col" v-if="match.status == 'inprogress'">
                                        <div class="active team-wrap"><span class="competition-text">{{match.cfs}}</span></div>
                                    </div>
                                    <div class="playcard-col" v-else>
                                        <div class="active team-wrap"><span class="competition-text">vs</span></div>
                                    </div>
                                    <div class="playcard-col">
                                        <div class="team-wrap" v-if="!match.competitors[1].is_home">
                                            <div class="team-logo">
                                                <img loading="lazy" :onerror="$teamDefaultLogo()" :src="(sportLogoPath['rugby'] + match.competitors[1].team.id + '_90x90.webp')" :alt="match.competitors[1].team.name" width="53" height="36">
                                            </div>
                                            <span class="team-title">
                                                <span class="team-title-in">
                                                    <span class="in-name ">{{ match.competitors[1].team.name }}</span>
                                                </span>
                                            </span>
                                        </div>
                                        <div class="team-wrap" v-else>
                                            <div class="team-logo">
                                                <img loading="lazy" :onerror="$teamDefaultLogo()" :src="(sportLogoPath['rugby'] + match.competitors[0].team.id + '_90x90.webp')" :alt="match.competitors[0].team.name" width="53" height="36">
                                            </div>
                                            <span class="team-title">
                                                <span class="team-title-in">
                                                    <span class="in-name ">{{ match.competitors[0].team.name }}</span>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <!-- <p>{{ (match.status=='inprogress') ? match.current_period : getCurrentTimeZone(match.datetime) }}</p> -->
                                <p>{{ (match.status=='inprogress') ? match.current_period : match.datetime | matchSliderDateTime }}</p>
                            </router-link>
                        </div>
                    </section>

                    <section class="swiper-slide" :class="(activeSport == 'all')?'':'d-none' " v-for="(match,index) in matches" :key="match.id">
                        <div class="sabc_livecenter_box">
                            <router-link :to="{ name: 'matchDetail.info', params: { slug: match.slug, id: match.id } }">
                                <ul class="match-head-ul">
                                    <li>
                                        <!-- <strong class=" text-uppercase" :class="(match.status == 'Live') ? 'text-live' : '' || (match.status == 'Finished') ? 'text-result' : '' || (match.status == 'Not Started') ? 'text-upcoming' : ''"> {{ match.status }} </strong> -->
                                        <span v-if="match.comment" class="live">LIVE</span>
                                        <strong class="sport-name"> Cricket </strong>
                                    </li>
                                    <!-- <li> {{ match.matchno }} {{ match.matchtype }} </li> -->
                                    <li> {{ match.matchno }} {{ match.matchtype }} </li>
                                </ul>
                                <div class="matchcard-body">
                                    <div class="playcard-col">
                                        <div class="team-wrap">
                                            <div class="team-logo">
                                                <img loading="lazy" :src="(sportLogoPath['cricket'] + match.team1.id + '_50x30.webp')" :alt="match.team1.country" width="53" height="36"><!-- )-->
                                            </div>
                                            <span class="team-title">
                                                <span class="team-title-in">
                                                    <span class="in-name ">{{ match.team1.name }}</span>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="playcard-col" v-if="match.status != 'Not Started'">
                                        <div :class="[(match.batting_team_id == match.team1.id) ? 'active' : '', 'team-wrap']">
                                            <div class="team-score">
                                                <span :class="(match.batting_team_id == match.team1.id) ? 'heighlighted' : ''"><!-- '-->
                                                    <span>{{ ((match.ht_score.split('&')).length > 1) ? match.ht_score.split('&')[0] : match.ht_score}}</span>
                                                    <span v-if="(match.ht_score.split('&')).length > 1">{{match.ht_score.split('&')[1]}} </span>
                                                </span>:
                                                <span :class="(match.batting_team_id == match.team2.id) ? 'heighlighted' : ''">
                                                    <span>{{ ((match.at_score.split('&')).length > 1) ? match.at_score.split('&')[0] : match.at_score}}</span>
                                                    <span v-if="(match.at_score.split('&')).length > 1">{{ match.at_score.split('&')[1]}}</span>
                                                </span>
                                            </div>
                                            <span class="d-block w-100" v-if="match.batting_team_id"> {{ overs(index, (match.batting_team_id == match.team1.id? 'team1':'team2')) }}</span>
                                        </div>
                                    </div>
                                    <div class="playcard-col" v-if="match.status == 'Not Started'">
                                        <div class="active team-wrap"><span class="competition-text">vs</span></div>
                                    </div>
                                    <div class="playcard-col">
                                        <div
                                                :class="[(match.batting_team_id == match.team2.id) ? 'active' : '', 'team-wrap']">
                                            <div class="team-logo">
                                                <img loading="lazy" :src="(sportLogoPath['cricket'] + match.team2.id + '_50x30.webp')" :alt="match.team2.country" width="53" height="36">
                                            </div>
                                            <span class="team-title">
                                                <span class="team-title-in">
                                                    <span class="in-name ">{{ match.team2.name }}</span>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <!-- <p>{{ (match.comment) ? match.comment : getCurrentTimeZone(match.gmt_datetime) }}</p> -->
                                <!-- <p>{{ (match.comment) ? match.comment : match.gmt_datetime | cricDateTimeTz }}</p> -->
                                <p v-if="match.comment">{{ match.comment }}</p>
                                <p v-else>{{ match.gmt_datetime | cricDateTimeTz }}</p>
                            </router-link>
                        </div>
                    </section>

                </div>
                <div v-if="soccer.length != 0 || rugby.length != 0 || matches.length != 0" class="swiper-button-prev" :class="(activeSport == 'all')?'':'d-none' "></div>
                <div v-if="soccer.length != 0 || rugby.length != 0 || matches.length != 0" class="swiper-button-next" :class="(activeSport == 'all')?'':'d-none' "></div>
            </div>

            <div class="swiper mySwiperCricket" :class="(activeSport == 'cricket')?'':' d-none' ">
                <div class="swiper-wrapper" >
                    <section class="swiper-slide" :class="(activeSport == 'cricket')?'':'d-none' " v-for="(match,index) in matches" :key="match.id">
                        <div class="sabc_livecenter_box">
                            <router-link :to="{ name: 'matchDetail.info', params: { slug: match.slug, id: match.id } }">
                                <ul class="match-head-ul">
                                    <li>
                                        <!-- <strong class=" text-uppercase" :class="(match.status == 'Live') ? 'text-live' : '' || (match.status == 'Finished') ? 'text-result' : '' || (match.status == 'Not Started') ? 'text-upcoming' : ''"> {{ match.status }} </strong> -->
                                        <span v-if="match.comment" class="live">LIVE</span>
                                        <strong class="sport-name"> Cricket </strong>
                                    </li>
                                    <li> {{ match.matchno }} {{ match.matchtype }} </li>
                                </ul>
                                <div class="matchcard-body">
                                    <div class="playcard-col">
                                        <div class="team-wrap">
                                            <div class="team-logo">
                                                <img loading="lazy" :src="(sportLogoPath['cricket'] + match.team1.id + '_50x30.webp')" :alt="match.team1.country" width="53" height="36"><!-- )-->
                                            </div>
                                            <span class="team-title">
                                                <span class="team-title-in">
                                                    <span class="in-name ">{{ match.team1.name }}</span>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="playcard-col" v-if="match.status != 'Not Started'">
                                        <div :class="[(match.batting_team_id == match.team1.id) ? 'active' : '', 'team-wrap']">
                                            <div class="team-score">
                                                <span :class="(match.batting_team_id == match.team1.id) ? 'heighlighted' : ''">
                                                    <span>{{ ((match.ht_score.split('&')).length > 1) ? match.ht_score.split('&')[0] : match.ht_score}}</span>
                                                    <span v-if="(match.ht_score.split('&')).length > 1">{{match.ht_score.split('&')[1]}} </span>
                                                </span>:
                                                <span :class="(match.batting_team_id == match.team2.id) ? 'heighlighted' : ''">
                                                    <span>{{ ((match.at_score.split('&')).length > 1) ? match.at_score.split('&')[0] : match.at_score}}</span>
                                                    <span v-if="(match.at_score.split('&')).length > 1">{{ match.at_score.split('&')[1]}}</span>
                                                </span>
                                            </div>
                                            <span class="d-block w-100" v-if="match.batting_team_id"> {{ overs(index, (match.batting_team_id == match.team1.id? 'team1':'team2')) }}</span>
                                        </div>
                                    </div>
                                    <div class="playcard-col" v-if="match.status == 'Not Started'">
                                        <div class="active team-wrap"><span class="competition-text">vs</span></div>
                                    </div>
                                    <div class="playcard-col">
                                        <div
                                                :class="[(match.batting_team_id == match.team2.id) ? 'active' : '', 'team-wrap']">
                                            <div class="team-logo">
                                                <img loading="lazy" :src="(sportLogoPath['cricket'] + match.team2.id + '_50x30.webp')" :alt="match.team2.country" width="53" height="36">
                                            </div>
                                            <span class="team-title">
                                                <span class="team-title-in">
                                                    <span class="in-name ">{{ match.team2.name }}</span>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <!-- <p>{{ (match.comment) ? match.comment : getCurrentTimeZone(match.gmt_datetime) }}</p> -->
                                <!-- <p>{{ (match.comment) ? match.comment : match.gmt_datetime | cricDateTimeTz }}</p> -->
                                <p v-if="match.comment">{{ match.comment }}</p>
                                <p v-else>{{ match.gmt_datetime | cricDateTimeTz }}</p>
                            </router-link>
                        </div>
                    </section>
                </div>
                <div v-if="matches.length != 0" class="swiper-button-prev" :class="(activeSport == 'cricket')?'':'d-none' "></div>
                <div v-if="matches.length != 0" class="swiper-button-next" :class="(activeSport == 'cricket')?'':'d-none' "></div>
            </div>

            <SportMatchSlider v-if="soccer.length" :sport="'soccer'" :swiperClass="'mySwiperSoccer'" :activeSport="activeSport" :matches="soccer"></SportMatchSlider>
            <SportMatchSlider v-if="rugby.length" :sport="'rugby'" :swiperClass="'mySwiperRugby'" :activeSport="activeSport" :matches="rugby"></SportMatchSlider>

            <div class="sabc_mt_slider_nomatch soccer_matchslider_area" v-if="activeSport == 'soccer' && soccer.length == 0">
                <p>No Live Matches Today</p> <p>Go To <router-link :to="{ name: 'football_listing', params: { type: 'all', date:  selectedDate } }"> Soccer Match Center</router-link></p>
            </div>
            <div class="sabc_mt_slider_nomatch rugby_matchslider_area" v-if="activeSport == 'rugby' && rugby.length == 0">
                <p>No Live Matches Today</p> <p>Go To <router-link :to="{ name: 'rugby_listing', params: { type: 'all', date:  selectedDate } }"> Rugby Match Center</router-link></p>
            </div>
            <div class="sabc_mt_slider_nomatch cricket_matchslider_area" v-if="activeSport == 'cricket' && matches.length == 0">
                <p>No Live Matches Today</p> <p>Go To <router-link :to="{ name: 'cricket_listing', params: { type: 'all', date:  selectedDate } }"> Cricket Match Center</router-link></p>
            </div>
        </div>
    </section>
</template>
<script>
    import moment from 'moment'
    import SportMatchSlider from '@/components/Home/match-slider/sport-match-slider'
    import { Swiper, Navigation } from "swiper";
    Swiper.use([ Navigation ]);

    export default {
        name: "match-slider",
        data() {
            return {
                matches: [],
                soccer: [],
                rugby: [],
                activeSport: 'all',
                selectedDate: moment().format('YYYY-MM-DD'),
                remainingTimeInterval: '',
                sportLogoPath : {
                    soccer : this.$storageURL+'images/football/',
                    cricket : this.$storageURL+'images/cricket/',
                    rugby : this.$storageURL+'images/rugby/',
                }
            }
        },
        components: {SportMatchSlider},
        async mounted() {
            await this.getSliderMatches();
            await this.runSlider();
            this.remainingTimeInterval = setInterval(() => {
                this.getSliderMatches();
            }, 15000);
        },
        serverPrefetch() {
            this.getSliderMatches();
        },
        beforeDestroy() {
            clearInterval(this.remainingTimeInterval);
        },
        methods: {
            overs(index, team) {
                if (!this.matches[index] || !this.matches[index][team])
                    return '';
                let innings = this.matches[index].innings.filter(inning => inning.team_id == this.matches[index][team].id);
                return innings.length ? `${innings[innings.length - 1].overs} ${this.matches[index].matchtype == '100 Ball Match'? this.$t('cric.Balls') : this.$t('cric.Overs')}` : '';
            },
            getSliderMatches() {
                let params = {sport: this.activeSport};
                // return this.$axios.get(this.$SLIDER_MATCH, {params})
                return this.$prepareAPICall(this.$SLIDER_MATCH, params)
                        .then(response => {
                            // this.is_data_ready = true
                            if(response.data.result.cricket.length){
                                this.matches = response.data.result.cricket;
                            }
                            if(response.data.result.soccer.length){
                                this.soccer = response.data.result.soccer;
                            }
                            if(response.data.result.rugby.length){
                                this.rugby = response.data.result.rugby;
                            }
                        })
            },
            getCurrentTimeZone(value) {
                return moment(String(value)).format('DD MMM HH:mm ')
            },
            runSlider() {
                new Swiper('.mySwiper2, .mySwiperCricket, .mySwiperRugby, .mySwiperSoccer', {
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    slidesPerView: 3,
                    observer: true,
                    observeParents: true,
                    paginationClickable: true,
                    spaceBetween: 16,
                    autoplay: false,

                    breakpoints: {
                        1199: {
                            slidesPerView: 3,
                            spaceBetween: 16
                        },
                        767: {
                            slidesPerView: 2,
                            spaceBetween: 12
                        },
                        200: {
                            slidesPerView: 1,
                            spaceBetween: 10
                        }
                    }
                });
            },
            getSportsMatches(sportName){
                this.activeSport = sportName;
                this.getSliderMatches();
            },
            getMatchDate(dateTime){
                return moment(String(dateTime)).format('YYYY-MM-DD')
            },
        },
    }
</script>